<template>
  <h3 class="d-flex align-items-end justify-content-center pb-5 px-3">{{ text }}</h3>
</template>

<script>
export default {
  name: 'InfoBox',
  props: {
    text: String
  }
}
</script>

<style scoped>
h3 {
  color: #244564;
}
@media (min-width: 992px) {
  h3  {
    height: 50%;
  }
}
</style>
