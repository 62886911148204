<template>
  <h5 class="mt-3">Options</h5>

  <SearchOption
    v-for="(option, index) in [optionsPlayers, optionsHoles, optionsCart, optionsDate]"
    :selected="0"
    :key="ids[index]"
    :id="ids[index]"
    :label-logo="labelLogos[index]"
    :label-text="labelTexts[index]"
    :options="option"
    @changed="[updatePlayers, updateHoles, updateCart, updateDate][index]"
  />

</template>

<script>
import SearchOption from '@/components/SearchOption'

export default {
  name: 'OptionContainer',
  components: {
    SearchOption
  },
  data () {
    return {
      ids: [
        'optionsPlayers',
        'optionsHoles',
        'optionsCart',
        'optionsDate'
      ],
      labelLogos: [
        'user-group',
        'golf-ball-tee',
        'car-side',
        'calendar-days'
      ],
      labelTexts: [
        'Players',
        'Holes',
        'Cart',
        'Date'
      ],
      optionsPlayers: [
        { text: 'Any', value: 'any' },
        { text: '1', value: '1' },
        { text: '2', value: '2' },
        { text: '3', value: '3' },
        { text: '4', value: '4' }
      ],
      optionsHoles: [
        { text: 'Any', value: 'any' },
        { text: '9', value: '9' },
        { text: '18', value: '18' }
      ],
      optionsCart: [
        { text: 'Any', value: 'any' },
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' }
      ],
      optionsDate: []
    }
  },
  mounted () {
    this.optionsDate.length = 0
    this.axios
      .get('/dates')
      .then(response => {
        for (const element of response.data) {
          this.optionsDate.push({
            text: element,
            value: element
          })
        }
        this.updateDate(this.optionsDate[0].value)
      })
      .catch(error => {
        console.log(error)
      })

    this.updatePlayers(this.optionsPlayers[0].value)
    this.updateHoles(this.optionsHoles[0].value)
    this.updateCart(this.optionsCart[0].value)
  },
  methods: {
    updatePlayers (value) {
      this.$store.commit('SET_PLAYERS', value)
    },
    updateHoles (value) {
      this.$store.commit('SET_HOLES', value)
    },
    updateCart (value) {
      this.$store.commit('SET_CART', value)
    },
    updateDate (value) {
      this.$store.commit('SET_DATE', value)
    }
  }
}
</script>

<style scoped>

</style>
