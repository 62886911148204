<template>
  <!--  I have no idea why, but the input will overflow if I don't mx-2 in this wrapping div  -->
  <div class="mx-2 my-1">
    <div class="input-group input-group-sm">
      <label
        class="input-group-text"
        :for="id"><font-awesome-icon :icon="labelLogo"/>&nbsp;&nbsp;{{ labelText }}
      </label>
      <select
        class="form-select text-light text-center fw-bold"
        :id="id"
        @change="onChange($event)">
        <option
          v-for="(option, index) in options"
          :value="option.value"
          :key="option.value"
          :selected="selected === index ? true : null"
        >{{ option.text }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchOption',
  props: {
    id: String,
    labelLogo: String,
    labelText: String,
    selected: Number,
    options: Array
  },
  data () {
    return {
      test: null
    }
  },
  methods: {
    onChange (event) {
      this.$emit('changed', event.target.value)
    }
  }
}
</script>

<style scoped>
select {
  background-color: rgba(255, 255, 255, .15);
  border: none;
  font-size: 12px !important;
}
label {
  background-color: #BFD7EA;
  border: none;
  width: 95px;
}
select:hover {
  background-color: #25384f;
}
.form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='white' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='0' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}
</style>
