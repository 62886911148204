<template>
  <div class="container-fluid h-100">
    <div class="row h-100">
      <div class="col-lg-3 px-0 h-100">
        <Sidebar/>
      </div>
      <div class="col-lg-9 px-0 h-100">
        <Main/>
      </div>
    </div>
  </div>
</template>

<script>
import Main from '@/components/Main.vue'
import Sidebar from '@/components/Sidebar'

export default {
  name: 'Home',
  components: {
    Main, Sidebar
  }
}
</script>
