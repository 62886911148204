<template>
  <h5 class="mt-3">Courses</h5>

  <SelectedCourse
    v-for="course in selectedCourses"
    :course-name="course.name"
    :course-id="course.id"
    :key="course.id"
    @deleted="removeCourse">
  </SelectedCourse>

  <CourseAddButton
    @add-course="addCourse">
  </CourseAddButton>

  <transition name="fade">
    <CourseAddErrorText
      :message="errorText"
      v-if="errorText">
    </CourseAddErrorText>
  </transition>
</template>

<script>
import SelectedCourse from '@/components/SelectedCourse'
import CourseAddButton from '@/components/CourseAddButton'
import CourseAddErrorText from '@/components/CourseAddErrorText'

export default {
  name: 'CourseContainer',
  components: {
    SelectedCourse,
    CourseAddButton,
    CourseAddErrorText
  },
  data () {
    return {
      errorText: '',
      errorTextReset: null,
      selectedCourses: [
        // { name: 'Arlington Lakes Golf Club', id: '1476' },
        // { name: 'Bittersweet Golf Club', id: '1507' },
        // { name: 'Billy Caldwell Golf Course', id: '3311' },
        // { name: 'Highland Woods Golf Course', id: '3317' },
        // { name: 'Indian Boundary Golf Course', id: '3319' },
        // { name: 'Chevy Chase Country Club (IL)', id: '4856' },
        // { name: 'The Arboretum Golf Club', id: '6171' },
        // { name: 'Buffalo Grove Golf Course', id: '7713' },
        // { name: 'Stonewall Orchard Golf Club', id: '11392' }
        // { name: 'TEST MetroWest Golf Club TEST', id: '4062' },
        // { name: 'TEST Wildcat Golf Club Lakes TEST', id: '632' }
      ]
    }
  },
  methods: {
    setErrorText (value) {
      clearTimeout(this.errorTextReset)
      this.errorText = value
      this.errorTextReset = setTimeout(() => {
        this.errorText = ''
      }, 3000)
    },
    removeCourse (id) {
      this.selectedCourses.splice(this.selectedCourses.findIndex(item => item.id === id), 1)
      this.saveToLocalStorage()
    },
    addCourse (name, id) {
      // Return if already in list
      if (this.selectedCourses.filter(n => n.name === name).length > 0) {
        this.setErrorText('Course is already added')
        return
      }

      if (this.selectedCourses.length < 10) {
        this.selectedCourses.push({
          name: name,
          id: id
        })
        this.saveToLocalStorage()
      } else {
        this.setErrorText('Max 10 courses per search')
      }
    },
    saveToLocalStorage () {
      const parsed = JSON.stringify(this.selectedCourses)
      localStorage.setItem('selectedCourses', parsed)
    }
  },
  mounted () {
    if (localStorage.getItem('selectedCourses')) {
      try {
        this.selectedCourses = JSON.parse(localStorage.getItem('selectedCourses'))
      } catch (e) {
        localStorage.removeItem('selectedCourses')
      }
    }
    this.$store.commit('SET_COURSES', this.selectedCourses)
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
