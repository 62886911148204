<template>
  <div class="row g-0 mx-2 text-dark px-2 my-1 rounded-3">
    <span class="col-11">{{ courseName }}</span>
    <button @click="onDelete" type="button" class="btn col-1 text-end p-0">X</button>
  </div>
</template>

<script>
export default {
  name: 'SelectedCourse',
  props: {
    courseName: String,
    courseId: String
  },
  methods: {
    onDelete () {
      this.$emit('deleted', this.courseId)
    }
  }
}
</script>

<style scoped>
div {
  font-size: 14px !important;
  background-color: #BFD7EA;
  padding-top: .125rem;
  padding-bottom: .125rem;
}
button, button:hover, button:active {
  border: none;
  font-size: 14px;
}
</style>
