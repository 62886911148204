<template>
  <table class="table table-striped table-sm text-center">
    <thead>
    <tr>
      <th scope="col" @click="sort('time')">Time <font-awesome-icon :icon="direction" v-if="currentSort === 'time'"/></th>
      <th scope="col" @click="sort('course')">Course <font-awesome-icon :icon="direction" v-if="currentSort === 'course'"/></th>
      <th scope="col" @click="sort('rate')">Rate <font-awesome-icon :icon="direction" v-if="currentSort === 'rate'"/></th>
      <th scope="col" @click="sort('players')">Players <font-awesome-icon :icon="direction" v-if="currentSort === 'players'"/></th>
      <th scope="col" @click="sort('cart')">Cart <font-awesome-icon :icon="direction" v-if="currentSort === 'cart'"/></th>
      <th scope="col" @click="sort('holes')">Holes <font-awesome-icon :icon="direction" v-if="currentSort === 'holes'"/></th>
    </tr>
    </thead>
    <tbody>
    <!--  We can just use index as key because we're never going to update individual items in this list  -->
    <tr v-for="(teeTime, index) in teeTimes" :key="index">
      <td>{{ formatTime(teeTime.time) }}</td>
      <td>{{ teeTime.course }}</td>
      <td>${{ teeTime.rate }}</td>
      <td>{{ teeTime.players }}</td>
      <td>{{ teeTime.cart }}</td>
      <td>{{ teeTime.holes }}</td>
    </tr>
    </tbody>
  </table>
  <LoadingIcon v-if="loading"/>
  <InfoBox v-if="infoText" :text="infoText"/>
</template>

<script>
import LoadingIcon from '@/components/LoadingIcon'
import InfoBox from '@/components/InfoBox'

export default {
  name: 'TeeTimeTable',
  components: {
    InfoBox,
    LoadingIcon
  },
  data () {
    return {
      teeTimes: [],
      infoText: 'Enter your search terms to find tee times',
      loading: false,
      currentSort: 'time',
      currentSortDir: 'asc'
    }
  },
  methods: {
    stringToDate (dateString) {
      const s = dateString.split(':')
      return new Date(s[0], s[1] - 1, s[2], s[3], s[4])
    },
    formatTime (time) {
      const date = this.stringToDate(time)
      return date.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })
    },
    sort (field) {
      if (field === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
      }
      this.currentSort = field

      this.teeTimes.sort((a, b) => {
        let modifier = 1
        if (this.currentSortDir === 'desc') {
          modifier = -1
        }
        let aVar = a[this.currentSort]
        let bVar = b[this.currentSort]

        // Compare date objects if time
        if (field === 'time') {
          aVar = this.stringToDate(aVar)
          bVar = this.stringToDate(bVar)
        }

        if (aVar < bVar) {
          return -1 * modifier
        }
        if (aVar > bVar) {
          return modifier
        }
        return 0
      })
    },
    queryTeeTimes () {
      this.teeTimes.length = 0
      const courses = this.$store.getters.COURSES
      this.currentSort = 'time'
      this.currentSortDir = 'asc'

      if (courses.length === 0) {
        this.infoText = 'No courses selected'
        return
      }

      this.infoText = ''
      this.loading = true
      const players = this.$store.getters.PLAYERS
      const holes = this.$store.getters.HOLES
      const cart = this.$store.getters.CART
      const date = this.$store.getters.DATE
      // http://localhost:5000/teetimes?date_day=02-25-2022&players=any&holes=any&cart=any&courses=4062,632
      const url = `/teetimes?date_day=${date}&players=${players}&holes=${holes}&cart=${cart}&courses=${courses.map(u => u.id).join(',')}`

      this.axios
        .get(url)
        .then(response => {
          this.loading = false
          this.teeTimes = response.data
          if (this.teeTimes.length === 0) {
            this.infoText = 'No tee times found'
          }
        })
        .catch(error => {
          console.log(error)
          this.loading = false
          this.infoText = error.response.data
        })
    }
  },
  computed: {
    searchButtonClicked () {
      return this.$store.getters.SEARCH_BUTTON_CLICKED
    },
    direction () {
      if (this.currentSortDir === 'asc') {
        return 'square-caret-up'
      }
      return 'square-caret-down'
    }
  },
  watch: {
    searchButtonClicked (value) {
      if (value) {
        this.queryTeeTimes()
        this.$store.commit('SET_SEARCH_BUTTON_CLICKED', false)
      }
    }
  }
}
</script>

<style scoped>
td {
  padding: 2px;
}
</style>
