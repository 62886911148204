import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faGolfBallTee,
  faCirclePlus,
  faCarSide,
  faUserGroup,
  faCalendarDays,
  faSpinner,
  faCaretSquareUp,
  faCaretSquareDown
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import axios from 'axios'
import { createStore } from 'vuex'

library.add(
  faGolfBallTee,
  faCirclePlus,
  faCarSide,
  faUserGroup,
  faCalendarDays,
  faSpinner,
  faCaretSquareUp,
  faCaretSquareDown
)

const store = createStore({
  state () {
    return {
      searchButtonClicked: false,
      players: null,
      holes: null,
      cart: null,
      date: null,
      courses: []
    }
  },
  getters: {
    SEARCH_BUTTON_CLICKED (state) {
      return state.searchButtonClicked
    },
    PLAYERS (state) {
      return state.players
    },
    HOLES (state) {
      return state.holes
    },
    CART (state) {
      return state.cart
    },
    DATE (state) {
      return state.date
    },
    COURSES (state) {
      return state.courses
    }
  },
  mutations: {
    SET_SEARCH_BUTTON_CLICKED (state, clicked) {
      state.searchButtonClicked = clicked
    },
    SET_PLAYERS (state, players) {
      state.players = players
    },
    SET_HOLES (state, holes) {
      state.holes = holes
    },
    SET_CART (state, cart) {
      state.cart = cart
    },
    SET_DATE (state, date) {
      state.date = date
    },
    SET_COURSES (state, courses) {
      state.courses = courses
    }
  }
})

const app = createApp(App).use(router).use(store).component('font-awesome-icon', FontAwesomeIcon)
app.config.globalProperties.axios = axios
app.mount('#app')
