<template>
  <button @click="onClick" type="button" class="btn w-100 mt-5 mb-3">Search for Tee Times</button>
</template>

<script>
export default {
  name: 'SearchButton',
  methods: {
    onClick () {
      this.$store.commit('SET_SEARCH_BUTTON_CLICKED', true)
    }
  }
}
</script>

<style scoped>
button {
  background-color: #BFD7EA;
  border: none;
}
button:active {
  background-color: #25384f;
}
</style>
