<template>
  <router-view/>
</template>

<script>
export default {

}
</script>

<style>
@media (min-width: 992px) {
  html, body  {
    height: 100%;
  }
}
</style>
