<template>
  <div class="h-100">
    <TeeTimeTable/>
  </div>
</template>

<script>
import TeeTimeTable from '@/components/TeeTimeTable'

export default {
  name: 'Main',
  components: {
    TeeTimeTable
  }
}
</script>

<style scoped>
@media (min-width: 992px) {
  div {
    overflow-y: scroll;
  }
}
</style>
