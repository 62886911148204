<template>
  <div class="mt-3 mx-2">
    <button
      v-if="buttonShow"
      @click="addCourseStart"
      type="button"
      class="btn text-light btn-sm">
      <font-awesome-icon icon="circle-plus"/>&nbsp;Add a Course
    </button>
    <input
      class="form-control-sm w-100"
      list="courseSearchOptions"
      placeholder="Add a Course"
      ref="input"
      v-model="inputValue"
      v-if="inputShow"
      @blur="inputLoseFocus"
      type="text"
      @change="addCourse($event)">
    <datalist id="courseSearchOptions" v-if="inputShow">
      <option
        v-for="course in foundCourses"
        :value="course.display"
        :key="course.id"
      />
    </datalist>
  </div>
</template>

<script>
import { nextTick } from 'vue'

export default {
  name: 'CourseAddButton',
  data () {
    return {
      buttonShow: true,
      inputShow: false,
      inputValue: null,
      stoppedTypingTimeout: null,
      foundCourses: []
    }
  },
  mounted () {
    this.startTyping = Date.now()
  },
  watch: {
    inputValue (value) {
      clearTimeout(this.stoppedTypingTimeout)

      if (!this.inputShow || value.length < 5) {
        this.foundCourses.splice(0)
        return
      }

      this.stoppedTypingTimeout = setTimeout(() => {
        this.findCourses()
      }, 400)
    }
  },
  methods: {
    async addCourseStart () {
      this.buttonShow = false
      this.inputShow = true

      // Wait for the next DOM update, so we don't miss selecting the input
      await nextTick()
      this.$refs.input.focus()
    },
    inputLoseFocus () {
      this.inputShow = false
      this.buttonShow = true
    },
    addCourse (event) {
      const course = this.foundCourses.filter(n => {
        return n.display === event.target.value
      })[0]

      if (course) {
        this.$emit('addCourse', course.name, course.id.toString())
      }
      // TODO This is still messy on different browsers, doesn't always clear
      this.inputValue = ''
      this.foundCourses.splice(0)
    },
    findCourses () {
      const url = `/courses?search=${this.inputValue}`
      this.axios
        .get(url)
        .then(response => {
          this.foundCourses = response.data.slice(0, 5)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>

<style scoped>
button {
  background-color: rgba(255,255,255,.15);
  border: none;
}
button:hover {
  background-color: #25384f;
}
input {
  color: #244564;
}
</style>
