<template>
  <div class="h-100 px-3 pt-2 text-light">
    <h2 class="mb-0"><font-awesome-icon icon="golf-ball-tee"/>&nbsp;Zeta Golf</h2>
    <OptionContainer/>
    <CourseContainer/>
    <SearchButton/>
  </div>
</template>

<script>
import CourseContainer from '@/components/CourseContainer'
import OptionContainer from '@/components/OptionContainer'
import SearchButton from '@/components/SearchButton'

export default {
  name: 'Sidebar',
  components: {
    SearchButton,
    CourseContainer,
    OptionContainer
  }
}
</script>

<style scoped>
div {
  background: #244564;
}
</style>
