<template>
  <div class="mx-2 pt-1">
    <p class="m-0 p-0">{{ message }}</p>
  </div>
</template>

<script>
export default {
  name: 'CourseAddErrorText',
  props: {
    message: String
  }
}
</script>

<style scoped>
p {
  color: orangered;
  font-size: 14px;
}
</style>
