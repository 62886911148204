<template>
  <h1
    :style="styleString"
    class="d-flex align-items-end justify-content-center pb-5">
    <font-awesome-icon icon="spinner" class="fa-rotate-by"/>
  </h1>
</template>

<script>
export default {
  name: 'LoadingIcon',
  data () {
    return {
      rotate: null,
      rotationDegrees: 0,
      styleString: '--fa-rotate-angle: 45deg;'
    }
  },
  methods: {
    rotateIcon () {
      this.rotate = setInterval(() => {
        if (this.rotationDegrees >= 360) {
          this.rotationDegrees = 0
        } else {
          this.rotationDegrees += 3
        }
        this.styleString = '--fa-rotate-angle: ' + this.rotationDegrees.toString() + 'deg;'
      }, 10)
    }
  },
  mounted () {
    this.rotateIcon()
  },
  unmounted () {
    clearInterval(this.rotate)
  }
}
</script>

<style scoped>
h1 {
  color: #244564;
  font-size: 48px;
}
@media (min-width: 992px) {
  h1  {
    height: 50%;
  }
}
</style>
